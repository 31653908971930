<template>
  <b-card title="Create Awesome 🙌">
    <b-card-text>This is your second page.</b-card-text>
    <b-card-text>Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot cake dragée chupa chups jujubes. Macaroon liquorice cookie wafer tart
      marzipan bonbon. Gingerbread jelly-o dragée chocolate.</b-card-text>
    <b-alert
      variant="primary"
      show
    >
      <h4 class="alert-heading">
        Lorem ipsum dolor sit amet
      </h4>
      <div class="alert-body">
        Lorem ipsum dolor sit amet
        <b-link class="alert-link">
          consectetur
        </b-link>
        adipisicing elit. Ducimus, laborum!
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import { BCard, BCardText, BAlert } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BAlert,
  },
}
</script>

<style></style>
